
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const DeviceManagement = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [carrierPage, setCarrierPage] = useState(1);
    const [popupOpen, setPopupOpen] = useState(false);    
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [newCarrierRow, setNewCarrierRow] = useState(null);
    const [carrierErrors, setCarrierErrors] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [carriers, setCarriers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [access, setAccess] = useState([]);
    const [newCarrier, setNewCarrier] = useState({ carrier_index: '', name: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [page, setPage] = useState(1);

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(role >= 2){
            checkAccess();
        }
    }, [role]);

    useEffect(() => {
        getCarrierList(page);
    }, [page]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getCarrierList = async (page) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/get-cia-carrier_list?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setCarriers(response.data.data);
        // setTotalItem(response.data.totalItem);
    }

    const handlePageChange  = (pageNumber) => {
        setCarrierPage(pageNumber);
    }

    // Add a new row
    const handleAdd = (e) => {
        e.preventDefault();
        setNewCarrierRow({ id: "", name: "" });
        setCarrierErrors({});
        setIsAdd(true);
    };

    const handleEdit = (index) => {
        const carrier = carriers[index];
        setNewCarrier(carrier);
        setIsEditing(true);
        setEditIndex(index);
        setIsAdd(false);
        setErrors({});
    };

    

    // Handle input change for the new row
    const handleInputChange = (e) => {
        setNewCarrier({ ...newCarrier, [e.target.name]: e.target.value });
    };

    const validateCarrierInputs = () => {
        const carrierErrors = {};
        if (!newCarrierRow.name.trim()) carrierErrors.name = "Carrier Name is required.";
        setCarrierErrors(carrierErrors);
        return Object.keys(carrierErrors).length === 0;
    };

    // Save the new row
    const handleCarrierAdd = async (e) => {
        e.preventDefault();

        if (!validateCarrierInputs()) return;
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/get-carrier-management-action`, newCarrierRow, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            setIsSuccess(true);
            setMsg(response.data.msg);
            window.location.reload();
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    };

    const validate = () => {
        const errors = {};
        if (typeof newCarrier.carrier_index === 'string' && !newCarrier.carrier_index.trim()) {
          errors.carrier_index = 'Index is required';
        }
        if (!newCarrier.name.trim()) {
          errors.name = 'Name is required';
        }
        return errors;
    };

    const handleSave = async () => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        let updateObj = newCarrier;
        if (isEditing) {
            // Update carrier
            
            // const { id, name, carrier_index } = newCarrier;
            // updateObj = { id, name, carrier_index };
            
          }
          try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/cia-carrier-action`,updateObj,{headers: {
                Authorization: `Bearer ${token}`
            }});
            setPopupOpen(false);
            if(response.data.status == false){
                alert(response.data.msg);
                setButtonDisabled(false);
                return;
            }else{
                window.location.reload();
            }
           
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
           
        }
          setErrors({});
    }

    const handleActiveCarrier = async (event, index, id) => {
        const { name, checked } = event.target;

        setCarriers((prevSetting) => {
            const updatedFormData = [...prevSetting];
            updatedFormData[index] = {
                ...updatedFormData[index], // Spread the existing fields of the object
                [name]: checked ? 1 : 0,  // Update the specific field based on 'checked' value
            };
            return updatedFormData;
        });
        console.log(name, checked);
        const action = checked ? 1 : 0;
        // const shouldDelete = window.confirm("Are you sure you want to enable this carrier?");
        // if (shouldDelete) {
            try {
                
                await axios.get(`${baseUrl}/cia-carrier-action/${id}?action=${action}`,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                window.location.reload();
            } catch (error) {
                console.log(error);
            }
        // }
    }
    
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Device Management</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 common-white-shadow-background setting-section">
                           
                        { isAdd && 
                            <div className="common-white-shadow-background setting-section">
                                <div className="row">
                                    <div className="col-sm-4" >
                                        {/* <label>Index</label> */}
                                        <input
                                            type="number"
                                            name="carrier_index"
                                            placeholder="Index"
                                            value={newCarrier.carrier_index}
                                            onChange={handleInputChange}
                                        />
                                        {errors.carrier_index && <div className="error error-message">Please enter Index.</div>}
                                    </div>
                                    <div className="col-sm-4" >
                                        {/* <label>Name</label> */}
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={newCarrier.name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && <div className="error error-message">Please enter carrier name.</div>}
                                    </div>
                                    <div className="col-sm-2 boxinput company-search" >
                                        <a href="#" className={buttonDisabled ? 'disabled-link search-btn clear-btn' : 'search-btn clear-btn'}  onClick={handleSave} >Save</a>
                                    </div>
                                </div>
                            </div>}
            
        
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body cia-carrier-list">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        <th>Platform Type</th>
                                        <th>Carrier</th>
                                        <th>Destination Number</th>
                                        {role < 2 && <th><span className='success'>Enable</span>/<span className='error'>Disable</span></th>}
                                        {role < 2 && <th>Edit</th>}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {carriers.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.platform_type == 1 ? "Android" : "IOS" }</td>
                                            <td>{item.name }</td>
                                           
                                            <td>
                                                {editIndex === index ? 
                                                    <>
                                                    <input
                                                        type="number"
                                                        name="number"
                                                        placeholder="Number"
                                                        value={newCarrier.number}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.name && <div className="error error-message">Please enter Number.</div>}</> : 
                                                    item.number
                                                }
                                            </td>
                                            {role < 2 &&<td>
                                            <div className="switchbtn">
                                                            <input type="checkbox" id={`action-${index}`}  className="checkbox"   checked={item.status == 1} name="status" onChange={(e) => handleActiveCarrier(e, index, item.id)} /> <label
                                                                htmlFor={`action-${index}`} className="toggle">
                                                                <p>
                                                                </p>
                                                            </label>
                                                        </div>
                                            </td>}
                                            {role < 2 && <td>
                                            {editIndex === index && <a href="#" className={buttonDisabled ? 'disabled-link' : ''}  onClick={handleSave} ><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Active" title="Use this option to enable customer" /></a>}
                                                {item.status == 1 && <a onClick={() => handleEdit(index)}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit user" /></a>}
                                                {/* {item.status == 1 &&<a  onClick={() => disableCarrier(item.id)}><img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`} alt="Active" title="Use this option to disable user" /></a>}
                                                {item.status == 0 && <a onClick={() => handleActiveCarrier(item.id)}><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Edit" title="Use this option to enable user" /></a>} */}
                                            </td>}
                                            
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                </div>
        
            </div>
            </div>
    )
}
 
export default DeviceManagement
