
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const ENRMSSettings = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [carrierPage, setCarrierPage] = useState(1);
    const [popupOpen, setPopupOpen] = useState(false);    
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [newCarrierRow, setNewCarrierRow] = useState(null);
    const [carrierErrors, setCarrierErrors] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [carriers, setCarriers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdd, setIsAdd] = useState(false);
    const [access, setAccess] = useState([]);
    const [stopENRMSSwitch, setStopENRMSSwitch] = useState(false);
    const [selectedOcrOption, setSelectedOcrOption] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(role >= 2){
            checkAccess();
        }
        
    }, [role]);

    useEffect(() => {
        if(token){
            getstopProcessSwitch();
            getOcrLibrary();
        }
    }, [token, carrierPage]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getstopProcessSwitch = async() => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/cia-process-switch`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setStopENRMSSwitch(response.data.data?.[0]?.cia_stop_process_switch == 2 ? true : false);
    }

  
    const getOcrLibrary = async() => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/cia-get-ocr-library`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setSelectedOcrOption(response.data.name);
        //setStopENRMSSwitch(response.data.data?.[0]?.cia_stop_process_switch == 2 ? true : false);
    }

    const handleOptionChange = async(event) => {
        setSelectedOcrOption(event.target.value);
        console.log("Selected Option:", event.target.value);
        
        setSuccessMessage(""); // Reset previous messages
        setErrorMessage("");

        setPopupOpen(true);
        const postObj = {
            name:event.target.value
        }
        try {
            const response = await axios.post(`${baseUrl}/cia-set-ocr-library`, postObj,{headers: { Authorization: `Bearer ${token}`}});
            console.log(response);
            setPopupOpen(false);
            // Set success message
            if(response.data.isSuccess == true){
                setSuccessMessage(response.data.msg);
            }else{
                setErrorMessage(response.data.msg); 
            }
            setTimeout(() => {
                setSuccessMessage("");
                setErrorMessage("");
            }, 2000);
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage("An error occurred while changing the library.");
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
        }
    };

    const handleStopENRMS = async(event) => {
        const { checked } = event.target;
        setStopENRMSSwitch(checked); 
        try {
                
            await axios.post(`${baseUrl}/stop-all-cia-process`, {stop_enrms_switch: checked},{headers: {
                Authorization: `Bearer ${token}`
            }});
            window.location.reload();
        } catch (error) {
            console.log(error);
        }   
    };

    const handlePageChange  = (pageNumber) => {
        setCarrierPage(pageNumber);
    }

    // Add a new row
    const handleAdd = (e) => {
        e.preventDefault();
        setNewCarrierRow({ id: "", name: "" });
        setCarrierErrors({});
        setIsAdd(true);
    };

    const handleEdit = (e,index) => {
        e.preventDefault();
        const carrier = carriers[index];
        setNewCarrierRow(carrier);
        setIsAdd(false);
        setEditIndex(index);
        setCarrierErrors({});
    };

    

    // Handle input change for the new row
    const handleInputChange = (e, field) => {
        setNewCarrierRow({ ...newCarrierRow, [field]: e.target.value });
        setCarrierErrors({ ...carrierErrors, [field]: "" });
    };

    const validateCarrierInputs = () => {
        const carrierErrors = {};
        if (!newCarrierRow.name.trim()) carrierErrors.name = "Carrier Name is required.";
        setCarrierErrors(carrierErrors);
        return Object.keys(carrierErrors).length === 0;
    };

    // Save the new row
    const handleCarrierAdd = async (e) => {
        e.preventDefault();

        if (!validateCarrierInputs()) return;
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
            const response = await axios.post(`${baseUrl}/get-carrier-management-action`, newCarrierRow, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            setIsSuccess(true);
            setMsg(response.data.msg);
            window.location.reload();
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    };

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Settings</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {role <= 2 && <div className="common-white-shadow-background setting-section">
                        <div className="settingbox">
                            <label>E-NRMS Process (Green=process is ok to run.  Red=do not process any E-NRMS)</label>
                            <div className="switchbtn">
                                <input type="checkbox" id="stop_enrms_process" className="checkbox"  checked={stopENRMSSwitch === true} name="stop_enrms_process" onChange={handleStopENRMS}  /> <label
                                    htmlFor="stop_enrms_process" className="toggle">
                                    <p>
                                    </p>
                                </label>
                            </div>

                        </div>
                    </div>}

                    {role <= 2 && <div className="ocr-box common-white-shadow-background setting-section">
                        {successMessage && (
                            <span className="alert alert-success mt-3" role="alert">
                            {successMessage}
                            </span>
                        )}
                        {errorMessage && (
                            <span className="alert alert-danger mt-3" role="alert">
                            {errorMessage}
                            </span>
                        )}
                        <div className="settingbox">
                                <label>OCR library to use</label>
                                <div className="form-check">
                                <label className="form-check-label" htmlFor="AWS" for="AWS">
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="AWS"
                                    value="AWS"
                                    checked={selectedOcrOption === "AWS"}
                                    onChange={handleOptionChange}
                                    />
                                    AWS
                                </label>
                                </div>

                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="Tesseract" for="Tesseract">
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="Tesseract"
                                    value="Tesseract"
                                    checked={selectedOcrOption === "Tesseract"}
                                    onChange={handleOptionChange}
                                    />
                                    
                                    Tesseract
                                    </label>
                                </div>
                        </div>
                    </div>}
                    </div>
                </div>
        
            </div>
            </div>
    )
}
 
export default ENRMSSettings
