/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import DatePicker from 'react-datepicker';
// require("bootstrap/less/bootstrap.less");
 
const Logs = () => {
    // const limit = 50;
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [cid, setCid] = useState("");
    const [recordList, setRecordList] = useState([]);
    const [recordListCount, setRecordListCount] = useState(0);
    //const [usageList, setUsageList] = useState([]);
    const [role, setRole] = useState('');
    // const [page, setPage] = useState(1);
    const [companyList, setcompanyList] = useState([]);
    // const [totalItem, setTotalItem] = useState(0);
    const [selectedValue, setSelectedValue] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [displayDate, setDisplayDate] = useState("");
    const [displayToDate, setDisplayToDate] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const [searchToDate, setSearchToDate] = useState("");
    const [selectedApiValue, setSelectedApiValue] = useState("");
    const navigate = useNavigate();
 
    const apiList = ["trunks-api", "states-api", "lata-api", "rate-center-api","search-number-api","order-new-number-api","order-detail-api","cname-order-api","disconnect-order-api",""];

    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);
    useEffect(() => {
        if(role){
            checkAccess(role);
        }
    }, [role]);
    useEffect(() => {
        if(token){
         getCustomerNameList();
        }
     }, [token]);
     useEffect(() => {
        if(selectedValue && role){
            console.log(selectedValue);
            //getNumberStatusLog( selectedValue);
        }
        
    }, [selectedValue]);
//BAP API Stastics
// From - to Date filter add on api log
// From - to Date filter add on tns log


 
   
     // Handle change event
        const handleChangeApi = (event) => {
            setSelectedApiValue(event.target.value);
        };
    
        const checkAccess = async (role) => {
            if(role > 2 ){
                navigate('/dashboard');
            }
        }
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
            if(decoded.role >2){
                setSelectedValue(decoded.cid);
            }
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // const allArray = response.data.slice(1);
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
            
        } catch (error) {
           console.log(error);
            
        }
    };

    // const handlePageChange  = (pageNumber) => {
    //     // console.log(`active page is ${pageNumber}`);
    //     setPage(pageNumber);
    // }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

 
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
            if(decoded.role >2){
                setSelectedValue(decoded.cid);
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
    };
    // const getNumberStatusLog = async (cid) => {
    //     // console.log(page);
    //     setPopupOpen(true);
    //     const response = await axiosJWT.get(`${baseUrl}/get-tns-number-status-log?company=${cid}`, {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
    //     setPopupOpen(false);

    //     setUsageList(response.data.data);
    // }


    const handleFileView = async (detail) => {
        try {
            const cleanedString = detail.message.replace('data: ', '');
            const data = {
                "message": JSON.parse(cleanedString) ?? '',
                "created_at" : convertToDate(detail.timestamp)
            }
            const newTab = window.open('', '_blank');
            newTab.document.write('<html><head><title>JSON Object</title></head><body><pre>' + JSON.stringify(data, null, 2) + '</pre></body></html>');
            newTab.document.close();
        } catch (error) {
          console.error('Error downloading the file', error);
        }
    };

    const handleFileDownload = async(detail) => {
        // Implement file download logic here
        // console.log(`Downloading file: ${fileName}`);
        const cleanedString = detail.message.replace('data: ', '');
        const data = {
            "message": JSON.parse(cleanedString) ?? '',
            "created_at" : convertToDate(detail.timestamp)
        }

        const jsonString = JSON.stringify(data, null, 2);

        const blob = new Blob([jsonString], { type: 'application/json' });
      
        const url = URL.createObjectURL(blob);

        // Create a link element and set the download attribute
        const link = document.createElement('a');
        link.href = url;
        link.download = `file_${convertToDate(detail.timestamp)}.json`; // Set the desired file name

        // Append the link to the document and click it to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        // Release the object URL
        URL.revokeObjectURL(url);
      };

    const handleSearchDate = (date) => { 
       
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
         console.log(formattedDate);
         setSearchDate(formattedDate);
        setDisplayDate(date);
    };

    const handleSearchToDate = (date) => { 
       if(date != null){
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
         console.log(formattedDate);
         setSearchToDate(formattedDate);
         setDisplayToDate(date);
       }
    };


    const handleSearchSubmit = async() => {
        if(!selectedValue){
            alert("Please select a company");
            return;
        }
        if(!searchDate){
            alert( `Please select a date`);
            return;
        }
        if(!selectedApiValue){
            alert( `Please select a api name`);
            return;
        }
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/capi-log-list?date=${searchDate}&to_date=${searchToDate}&company=${selectedValue}&api=${selectedApiValue}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setRecordList(response.data.data);
        setRecordListCount(response.data.count);
    };

    const convertToDate = (timestamp) => {
        const date = new Date(timestamp);

        // Extract the month, day, and year from the Date object
        const month = date.getUTCMonth() + 1; // Months are zero-based in JavaScript
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();

        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        // Format the date to m/d/y
        const formattedDate = `${month}/${day}/${year}`;

        // Format the time to hh:mm:ss
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        // Combine date and time
        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        // Format the date to m/d/y
        // const formattedDate = `${month}/${day}/${year}`;
        return formattedDateTime;
    }
    
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>BAP API Logs & Statistics</h1>
                        </div>
                    </div>
                </div>
                {role <= 2 && <div className="common-white-shadow-background setting-section">
                    <div className="row">
                        <h3 className='comapny-label'>Select Company</h3>
                        <div className="inputbox">
                            <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                <option value="">Select Company</option>
                                {companyList.map((comp) => (                                                                    
                                        <option key={comp.id} value={comp.id}>
                                            {comp.cname}
                                        </option>  
                                    ))}

                            </select>
                        </div>
                    </div>
                </div>}
                <div className="common-white-shadow-background setting-section">
                    <div className="row">
                        <h3 className='comapny-label'>Api Logs</h3>
                        <div className="row">
                            <div className="row order-filter transection-filter">
                                <div className="col-md-10">
                                    <div className="boxinput">
                                        <DatePicker
                                            selected={displayDate}
                                            onChange={handleSearchDate}
                                            dateFormat="MM/dd/yyyy" // Set the desired date format
                                            placeholderText="From"
                                            className="from-date"
                                        />
                                        <DatePicker
                                            selected={displayToDate}
                                            onChange={handleSearchToDate}
                                            dateFormat="MM/dd/yyyy" // Set the desired date format
                                            placeholderText="To"
                                            className="from-date"
                                        />
                                        <select id="dropdown" value={selectedApiValue} onChange={handleChangeApi}>
                                                <option value="" disabled>
                                                Select an option
                                                </option>
                                                {apiList.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                                ))}
                                        </select>

                                        <button  className="search-btn" onClick={handleSearchSubmit}>View Logs</button>
                                    </div>
                                </div>
                                {recordListCount > 0 && <div className="col-md-2">
                                    <span className='count-text'>Count: {recordListCount}</span>
                                </div>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body real-time-stats">
                                    <table>
                                        <thead>
                                            <tr>
                                                {/*<th>Message</th>*/}
                                                <th>DateTime (UTC)</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {recordList.map((info, index) => (
                                                <tr key={index}>

                                                    <td><a className="breadcrumb-link" onClick={() => handleFileView(info)}>{convertToDate(info.timestamp)}</a></td>
                                                    <td><a  onClick={() => handleFileDownload(info)}><img src="/images/download.png" /></a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        </div>
    )
}
 
export default Logs