/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import Select from 'react-select';
import { useNavigate, Link } from 'react-router-dom';
 
const Step1 = ({ onNext,cid,role,token, formData, setFormData, setDMTList, setExistingDMTList }) => {
   
    const [company, setCompany] = useState([]);
    const [numberList, setNumberList] = useState([]);
    const [msg, setMsg] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [checkDigitMapping, setCheckDigitMapping] = useState(false);
    const [checkAdvanceDigitMapping, setCheckAdvanceDigitMapping] = useState(false);

    const navigate = useNavigate();
 
      useEffect(() => {
        if (token) {
          getUserCompany();  
        }
      }, [token]);
      useEffect(() => {
        if(formData.cid){
            getCustomerDigitMappingAccess();
        }
    }, [formData.cid]);
      useEffect(() => {
        if(formData.cid){
            getAllActiveNumber();
        }
      },[formData.cid]);
    

    useEffect(() => {
        if(role > 2){
            setFormData((prevData) => ({
                ...prevData,
                cid: cid,                
            }));
        }
    }, [role]);

    useEffect(() => {
        if(formData.methodType == 0){
            
            setFormData((prevData) => ({
                ...prevData,
                translation_type : 2
            }));
        }
    }, [formData.methodType]);

    const getCustomerDigitMappingAccess = async () => {
        try {
            setPopupOpen(true);
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${formData.cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            
            if(role > 2 && response.data.digit_mapping_switch !== 1){
                navigate("/dashboard");
            }else if(role <= 2 && response.data.digit_mapping_switch !== 1){
                setCheckDigitMapping(true);
            }else{
                setCheckDigitMapping(false);
                setCheckAdvanceDigitMapping(false);
            }
            if(role <= 2 || response.data.advance_digit_mapping_switch == 1){
                setCheckAdvanceDigitMapping(true);
            }
            
            setPopupOpen(false);

        } catch (error) {
            // setLoading(false);
            setPopupOpen(false);
           console.log(error);
            
        }
    };
 
    const getUserCompany = async () => {
        
        const response = await axios.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        let allArray;
        if(role <= 2){
            // allArray = response.data.slice(1);
            allArray = response.data.filter(item => item.id !== 1);
        }else{
            allArray = response.data;
        }
        
        setCompany(allArray);
    }
    const getAllActiveNumber = async () => {
        const response = await axios.get(`${baseUrl}/get-all-active-number/${formData.cid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    //    console.log(response.data.number_list);
       setNumberList(response.data.number_list);
    //    console.log(company);
      
        
    }

    const [validationErrors, setValidationErrors] = useState({
        cid: false,
        // number:false,
        alias:false,
        original_ani: false,
        original_dnis:false
        // lname: false,
        // officephone: false,
        // mobilephone: false

    });
    const formRef = useRef(null);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        let newValidationErrors = {
            cid: formData.cid === '',
            // number: formData.number === '',
            alias: formData.alias === '',
                        
          };
          if(formData.translation_type == ""){
            newValidationErrors.translation_type = formData.translation_type === ''      
          }
          if(formData.translation_type == 1){
            newValidationErrors.original_ani = formData.original_ani === ''      
          }
          if(formData.translation_type == 2){
            newValidationErrors.original_dnis = formData.original_dnis === ''           
          }
          if(formData.table_id == "" || formData.table_id == "0"){
            newValidationErrors.table_id = formData.table_id === '' || formData.table_id === '0'           
          }
          if((formData.methodType == 0 && formData.mapping_type == 1 && formData.translation_type == 2) || (formData.methodType == 1 && formData.mapping_type == 1 && formData.translation_type == 2) || (formData.methodType == 1 && formData.mapping_type == 1 && formData.translation_type == 1 && formData.translated_dnis)){
            console.log(formData);
            // const regex = /^1\d{10}$/;
            // newValidationErrors.translated_dnis = !regex.test(formData.translated_dnis);
            if(formData.translated_dnis){
                const response = await axios.post(`${baseUrl}/digit-mapping/check-translated`,{company_id: formData.cid, translated_dnis : formData.translated_dnis},{headers: {
                    Authorization: `Bearer ${token}`
                }});
                const isEmptyOrNotFound = !response.data || !response.data[0]?.data || response.data[0].data.length === 0;
                if(isEmptyOrNotFound === true){
                    newValidationErrors.translated_dnis_validation = isEmptyOrNotFound;
                }
            }else{
                newValidationErrors.translated_dnis = formData.translated_dnis === ''
            }
            
          }
          if(formData.mapping_type == 2){
            const tenDigitRegex = /^\d{10}$/;
            const elevenDigitRegex = /^1\d{10}$/;
            // const regex = /^\d{10}$/;
            if (tenDigitRegex.test(formData.translated_dnis)) {
                formData.translated_dnis = `1${formData.translated_dnis}`;  // Prepend '1'
            }else if (elevenDigitRegex.test(formData.translated_dnis)) {
                // No change needed, number is already in the correct format
            } else {
                newValidationErrors.translated_dnis_offnet_validation = true;
            }
            // if(!regex.test(formData.translated_dnis)){
            //     newValidationErrors.translated_dnis_offnet_validation = true;
            // }else{
            //     formData.translated_dnis = `1${formData.translated_dnis}`;
            // }
          }

          setValidationErrors(newValidationErrors);
      
          // Check if there are any validation errors
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
        //   console.log(hasErrors);
          if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {           
            console.log(formData);
            try {
            setPopupOpen(true);
            const aniNumberList = formData.translation_type == "1" ?  [formData.tn_number] : []
            const dnisNumberList = (formData.translation_type == "2" || formData.mapping_type == 2) ? [formData.tn_number] : [];
           
            const response = await axios.post(`${baseUrl}/get-digitmapping-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const existingResponse = await axios.post(`${baseUrl}/get-portal-existing-dmt-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            const existingDMT = existingResponse?.data ?? [];
            const updatedArray = response.data[0]?.data.map(obj => ({ ...obj, ["status"]: 1 })) ?? [];
            console.log(updatedArray);
            if(updatedArray.length > 0 || existingDMT.length > 0){
                setDMTList(updatedArray);
                setExistingDMTList(existingDMT)
                onNext();
            }else{
                const { tn_number, ...rest } = formData;
                const updatedObj = {
                    ...rest,
                    telephoneNumber: tn_number,  // Replace tn_number with tnnumber
                    // translated_dnis: '1' + translated_dnis        // Prepend '1' to the value of abc
                };
                const combineData = {
                    company_id : formData.cid,
                    mapping_type :formData.mapping_type,
                    import_type : 1,
                    rowList : [updatedObj],
                    updatedDMT : [],
                    existingDMTList: []
                }
                setPopupOpen(true);
                try {
                    const response = await axios.post(`${baseUrl}/digit-mapping/import`, combineData,{
                        headers: {
                             Authorization: `Bearer ${token}`

                        }
                    });
    
                    const allArray = response.data;
                    navigate("/digit-mapping");
                } catch (error) {
                    console.error(error)
                }
                setButtonDisabled(false);
                setPopupOpen(false)
                
            }
            } catch (error) {
                console.log(error);
                setButtonDisabled(false);
                setPopupOpen(false);
                if (error.response) {
                    setIsSuccess(false);
                    setMsg(error.response.data.msg);
                    window.scrollTo(0, 0);
                }
            }


            // onNext();
              
            // try {
            //     setButtonDisabled(true);
            //     setPopupOpen(true);
            //     await axios.post(`${baseUrl}/digit-mapping/add`,{formData:formData},{headers: {
            //         Authorization: `Bearer ${token}`
            //     }});
            //     setIsSuccess(true);
            //     setPopupOpen(false);
            //     navigate("/digit-mapping");
            // } catch (error) {
            //     setButtonDisabled(false);
            //     setPopupOpen(false);
            //     if (error.response) {
            //         setIsSuccess(false);
            //         setMsg(error.response.data.msg);
            //         window.scrollTo(0, 0);
            //     }
            // }
          }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === "cid"){
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                methodType: 0,
                mapping_type: "0",
                tn_number:'',
                alias:'',
                description:'',
                table_id: "0",
                translation_type: '',
                original_ani: '',
                ani_min_length:0,
                ani_max_length:40,
                original_dnis:'',
                dnis_min_length:0,
                dnis_max_length:40,
                translated_ani:'',
                translated_dnis:'',
                ani_action:'1',
                dnis_action:'1'
            }));
            setValidationErrors([]);
        }else if(name === "mapping_type"){
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                tn_number:'',
                alias:'',
                description:'',
                table_id: "0",
                translation_type: prevData.methodType ==  0 ? 2 : "",
                original_ani: '',
                ani_min_length:0,
                ani_max_length:40,
                original_dnis:'',
                dnis_min_length:0,
                dnis_max_length:40,
                translated_ani:'',
                translated_dnis:'',
                ani_action:'1',
                dnis_action:'1'
                             
            }));
            setValidationErrors([]);
        }
            if(name === "methodType" && value == 0){
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    translation_type : 2                
                }));
                setValidationErrors([]);
            }else if(name === "methodType" && value == 1){
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    translation_type : "",
                    mapping_type: ""                
                }));
                setValidationErrors([]);
            }else if(name === "mapping_type" && value == 2){
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                    translation_type : "",
                }));
            }else{
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,                
                }));
            }
            
            
    
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

    const handleSelectChange = (data, option) => {
        
        setFormData((prevData) => ({
            ...prevData,
            [option.name] : data.value
        }));
        
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [option.name]: false
        }));
    }

    const handleSelectAliasForNumber = (data, option) => {
       
        const name = company.find((item) => item.id == formData.cid).short_name;
        const mapping_type = formData.mapping_type;
        let alias;
        if(mapping_type == 1){
            alias = name+"_"+data.value;
        }else if(mapping_type == 2){
            alias = name.substring(0, 22)+"_OFFNET"+"_"+data.value;
        }

        
        const did_order = numberList.find((item) => item.tn_number == data.value)?.did_order ?? "0";
        let table_id;
        if(did_order == 0){
            table_id = 2;
        }else if(did_order == 2){
            table_id = 1;
        }
        setFormData({... formData, alias : alias, table_id : table_id, tn_number: data.value});
    }

    const handleTranslationType = (e) => {
        const type = e.target.value;
        let original_ani;
        let original_dnis;
        let ani_action;
        let dnis_action;
        if(type == 1){
            original_ani = formData.tn_number;
            original_dnis = "ANY";
            ani_action = 1;
            dnis_action = 2;

        }else if(type == 2){
            original_ani = "ANY";
            original_dnis = formData.tn_number;
            ani_action = 2;
            dnis_action = 1;
        }
        setFormData({... formData, dnis_action:dnis_action,ani_action:ani_action,original_ani : original_ani, original_dnis : original_dnis, translation_type: e.target.value});
    }
      
    useEffect(() => {
       
        if(formData.mapping_type == 2 || formData.translation_type == 2){
            const syntheticEvent = {
                target: {
                    value: 2 // Set the value to 2
                }
            };
            
            // Call the handleTranslationType function with the synthetic event
            handleTranslationType(syntheticEvent);
        }
        
    }, [formData.mapping_type, formData.tn_number]);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
        }
    };
 
    return (
        <>
            {popupOpen && (
                 <Loader /> 
            ) }
               
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Add Digit Mapping</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/digit-mapping"><span>&larr;</span> Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>Digit Mapping</h3>
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Select Company</label>
                                                        {role <=2 &&
                                                        <select name="cid" id="user_role"  onChange={handleInputChange} className={validationErrors.cid ? 'invalid' : ''} >
                                                            <option value="">Select Company</option>
                                                                 {company.map((comp) => (                                                                    
                                                                    <option key={comp.id} value={comp.id}>
                                                                        {comp.cname}
                                                                    </option>  
                                                            ))}
                                                        </select> }
                                                        {role > 2 && <select name="cid" id="user_role"  disabled onChange={handleInputChange} className={validationErrors.cid ? 'invalid' : ''} >
                                                            <option value="">Select Company</option>
                                                                 {company.map((comp) => (                                                                    
                                                                    <option key={comp.id} selected value={comp.id}>
                                                                        {comp.cname}
                                                                    </option>  
                                                            ))}
                                                        </select>}
                                                        {validationErrors.cid && <div className="error error-message">Please select a company.</div>}
                                                       
                                                    </div>
                                                    

                                                    {!checkDigitMapping && <>
                                                    <div className='col-12 dmt-type'>
                                                        <div className="settingbox radio-standard-box">
                                                            <label for="pastNumber"><input type="radio" id="pastNumber" name="methodType" value={0} checked={formData.methodType == 0} onChange={handleInputChange} />
                                                                Basic</label>          
                                                            {checkAdvanceDigitMapping && <label for="fileUpload"><input type="radio" id="fileUpload" name="methodType" value={1} checked={formData.methodType == 1} onChange={handleInputChange} />
                                                                Advance</label>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label>Digit Mapping type</label>
                                                        <select name="mapping_type" id="mapping_type"  value={formData.mapping_type} onChange={handleInputChange} className={validationErrors.mapping_type ? 'invalid' : ''} >
                                                            <option value="">Select Type</option>
                                                            <option value="1">Standard Digit Mapping</option>
                                                            <option value="2">Offnet Digit Mapping</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.mapping_type && <div className="error error-message">Please select a digit mapping type.</div>}
                                                    </div>
                                                    </>}
                                                    {(formData.mapping_type === "1" || formData.mapping_type === "2") &&
                                                    <>
                                                    <div className="col-12">
                                                        <label>Select Number</label>
                                                        <Select
                                                            classNamePrefix="select"
                                                            value={ {value: formData.tn_number,
                                                                label: formData.tn_number}}
                                                            name="tn_number"
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                                handleSelectAliasForNumber(selectedOption, name);
                                                            }}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                        />
                                                        {validationErrors.tn_number && <div className="error error-message">Please select a number.</div>}
                                                        {validationErrors.table_id && <div className="error error-message">Please select a valid number.</div>}
                                                       
                                                    </div> 
                                                    {formData.methodType == 1 && <div className="col-12">
                                                        <label>Alias</label>
                                                        <input type="text" name="alias" value={formData.alias}   className={validationErrors.alias ? 'invalid' : ''}  readOnly/>                                                        
                                                        {validationErrors.alias && <div className="error error-message">Please set valid alias.</div>}
                                                    </div>}
                                                    <div className="col-12">
                                                        <label>Description</label>
                                                        <input type="text" name="description" value={formData.description}  onChange={handleInputChange} />                                                        
                                                        
                                                    </div>
                                                    {/* {formData.methodType == 1 &&<div className="col-12">
                                                        <label>Digit Mapping Table</label>
                                                        <select name="table_id" id="table_id"  value={formData.table_id} disabled onChange={handleInputChange} className={validationErrors.table_id ? 'invalid' : ''} >
                                                            <option value="">Select Table</option>
                                                            <option value="1">8xx conversions:1</option>
                                                            <option value="2">DID_DNIS_Translations:2</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.table_id && <div className="error error-message">Please select a digit mapping type.</div>}
                                                    </div>} */}
                                                    
                                                    
                                                    {formData.methodType == 1 && formData.mapping_type === "1" && <div className="col-12">
                                                        <label>Translation type</label>
                                                        <select name="translation_type" id="translation_type" onChange={(e) => {handleInputChange(e); handleTranslationType(e);}} className={validationErrors.translation_type ? 'invalid' : ''} >
                                                            <option value="">Select Type</option>
                                                            <option value="1">ANI</option>
                                                            <option value="2">DNIS</option>
                                                                  
                                                            
                                                        </select> 
                                                        {validationErrors.translation_type && <div className="error error-message">Please select a Translation Type.</div>}
                                                       
                                                    </div>}
                                                    
                                                    {(formData.translation_type == 1 || formData.translation_type == 2) &&
                                                    <>
                                                    {formData.methodType == 1 &&  <div className="col-md-8">
                                                        <label>Origination ANI</label>
                                                        {formData.translation_type == 2 &&<><input type="text" name="original_ani" value={formData.original_ani}  placeholder="Any" onChange={handleInputChange} className={validationErrors.original_ani ? 'invalid' : ''} />
                                                        {validationErrors.original_ani && <div className="error error-message">Please enter Origination ANI.</div>}</>}
                                                        {formData.translation_type == 1 &&<>
                                                       
                                                        <Select
                                                            classNamePrefix="select"
                                                            name="original_ani"
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                            }}
                                                            value={{value: formData.original_ani, label : formData.original_ani}}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                            isDisabled ={true}
                                                        />
                                                        {validationErrors.original_ani && <div className="error error-message">Please select a Origination ANI.</div>}
                                                        </>}
                                                    </div>}
                                                    {formData.methodType == 1 &&  <div className="col-md-2">
                                                        <label>Minimum Length</label>
                                                        <input type="text" name="ani_min_length" value={formData.ani_min_length}  onChange={handleInputChange} className={validationErrors.ani_min_length ? 'invalid' : ''} />
                                                       
                                                    </div>}
                                                    {formData.methodType == 1 && <div className="col-md-2">
                                                        <label>Maximum Length</label>
                                                        <input type="text" name="ani_max_length" value={formData.ani_max_length}  onChange={handleInputChange} className={validationErrors.ani_max_length ? 'invalid' : ''} />
                                                                                                                
                                                    </div>}                                               
                                                    {formData.methodType == 1 && <div className="col-sm-12">
                                                        <label>Translated ANI</label>
                                                        <input type="number" name="translated_ani" value={formData.translated_ani}   onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} onChange={handleInputChange}  className={validationErrors.translated_ani ? 'invalid' : ''}/>
                                                        
                                                    </div>}
                                                    {formData.methodType == 1 && <div className="col-sm-12">
                                                        <label>ANI Action</label>
                                                        <select name="ani_action" id="ani_action"  value={formData.ani_action} onChange={handleInputChange} className={validationErrors.ani_action ? 'invalid' : ''} >
                                                            <option value="0">all</option>
                                                            <option value="1">match</option>
                                                            <option value="2">passthrough</option>
                                                            <option value="3">prepend</option>
                                                            
                                                        </select> 
                                                        
                                                    </div>}
                                                    
                                                    
                                                    {formData.methodType == 1 && <div className="col-md-8">
                                                        <label>Origination DNIS</label>
                                                        {formData.translation_type == 1 &&<>
                                                         <input type="text" name="original_dnis" value={formData.original_dnis}  placeholder="Any" onChange={handleInputChange} className={validationErrors.original_dnis ? 'invalid' : ''} />
                                                        {validationErrors.original_dnis && <div className="error error-message">Please Enter Origination DNIS.</div>} </>}
                                                        {formData.translation_type == 2 && 
                                                        <>
                                                        
                                                        <Select
                                                            classNamePrefix="select"
                                                            name="original_dnis"
                                                            value={{value: formData.original_dnis, label :formData.original_dnis}} 
                                                            onChange={(selectedOption, name) => {
                                                                handleSelectChange(selectedOption, name);
                                                            }}
                                                            options={numberList.map(item => ({
                                                                value: item.tn_number,
                                                                label: item.tn_number
                                                            }))}
                                                            isDisabled ={true}
                                                        /> 
                                                        {validationErrors.original_dnis && <div className="error error-message">Please Enter Origination DNIS</div>}</>}
                                                    </div>}
                                                    {formData.methodType == 1 && <div className="col-md-2">
                                                        <label>Minimum Length</label>
                                                        <input type="text" name="dnis_min_length" value={formData.dnis_min_length}  onChange={handleInputChange} className={validationErrors.dnis_min_length ? 'invalid' : ''} />
                                                        
                                                    </div>}
                                                    {formData.methodType == 1 && <div className="col-md-2">
                                                        <label>Maximum Length</label>
                                                        <input type="text" name="dnis_max_length" value={formData.dnis_max_length}  onChange={handleInputChange} className={validationErrors.dnis_max_length ? 'invalid' : ''} />
                                                                                                                
                                                    </div>}
                                                    <div className="col-sm-12">
                                                        <label>Translated DNIS</label>
                                                        <input type="number" name="translated_dnis" value={formData.translated_dnis}   onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} onChange={handleInputChange}  className={validationErrors.translated_dnis ? 'invalid' : ''}/>
                                                        {validationErrors.translated_dnis && <div className="error error-message">Please Enter Translated DNIS</div>}
                                                        {validationErrors.translated_dnis_validation && <div className="error error-message">No route found for Translated DNIS</div>}
                                                        {validationErrors.translated_dnis_offnet_validation && <div className="error error-message">Please Enter NPANXXNNNN format</div>}
                                                    </div>
                                                    
                                                    {formData.methodType == 1 && <div className="col-sm-12">
                                                        <label>DNIS Action</label>
                                                        <select name="dnis_action" id="dnis_action" value={formData.dnis_action}   onChange={handleInputChange} className={validationErrors.dnis_action ? 'invalid' : ''} >
                                                            <option value="0">all</option>
                                                            <option value="1">match</option>
                                                            <option value="2">passthrough</option>
                                                            <option value="3">noLNP</option>
                                                            <option value="4">prepend</option>
                                                            
                                                        </select> 
                                                      
                                                    </div>}
                                                  </>}
                                                    </>
                                                }
                                                
                                                </div>
                                            </div>
                                        </div>
                                        
                                    
                                        {checkDigitMapping && 
                                                        <div className="common-white-shadow-background setting-section">
                                                            <div className="row error">You don't have access to the features.</div>
                                                        </div>
                                                    }
                                        {!checkDigitMapping &&<div className="col-12">
                                        <input type="submit" value="Submit" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'} />
                                    </div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
    )
}

const Step2 = ({ onBack, onNext, token, formData, setFormData, dmtList, existingDMTList   }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [updatedDMT, setUpdatedDMT] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [digitMappingList, setDigitMappingList] = useState([]);
    const [existingDigitMappingList, setExistingDigitMappingList] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if(dmtList.length > 0){
            setDigitMappingList(dmtList);
            setUpdatedDMT(dmtList);
            // getDigitMappingList();
        }
    }, [dmtList]);
    useEffect(() => {
        if(existingDMTList.length > 0){
            setExistingDigitMappingList(existingDMTList)
        }

    }, [existingDMTList])

    // const getDigitMappingList = async () => {
    //     setPopupOpen(true);
       
    //     const aniNumberList = formData.translation_type === "1" ?  [formData.tn_number] : []
    //     const dnisNumberList = (formData.translation_type === "2" || formData.mapping_type == 2) ? [formData.tn_number] : [];
       
    //     const response = await axios.post(`${baseUrl}/get-digitmapping-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
   
    //     const updatedArray = response.data[0].data.map(obj => ({ ...obj, ["status"]: 1 }));
    //     setDigitMappingList(response.data[0].data);
    //     // setSelectedRows(response.data[0].data);
    //     setUpdatedDMT(updatedArray);
    //     setPopupOpen(false);
    // }
    const handleSwitchSelectAll = (e) => {
        if (selectAll) {
            const updatedArray = digitMappingList.map(obj => ({ ...obj, ["status"]: 0 }));
            setSelectedRows([]);
            setUpdatedDMT(updatedArray);
        } else {
            const updatedArray = digitMappingList.map(obj => ({ ...obj, ["status"]: 1 }));
            setSelectedRows(digitMappingList); // Select all rows
            setUpdatedDMT(updatedArray);
        }
        setSelectAll(!selectAll);
    };

    const handleToggleSelect = (index) => {
        const updatedSelectedRows = selectedRows.includes(digitMappingList[index])
            ? selectedRows.filter(item => item !== digitMappingList[index]) // Deselect
            : [...selectedRows, digitMappingList[index]]; // Select

        const updateArray = digitMappingList.map((obj) => {
            const existsInUpdated = updatedSelectedRows.some(updatedObj => updatedObj.alias === obj.alias);
            if(existsInUpdated){
                obj.status = 1;
            }else{
                obj.status = 0;
            }
            return obj;
        })
        setUpdatedDMT(updateArray);
        setSelectedRows(updatedSelectedRows);

    };

    const handleNext = async(e) => {
        e.preventDefault();
        const {name} = e.target;
        let importType; 
        if(name == "import"){
            importType = 1;
        }else if(name == "remove_import"){
            importType = 2;
        }else if(name == "remove_all_import"){
            importType = 3;
        }
        const { tn_number, ...rest } = formData;
        const updatedObj = {
            ...rest,
            telephoneNumber: tn_number,  // Replace tn_number with tnnumber
            // translated_dnis: '1' + translated_dnis        // Prepend '1' to the value of abc
        };
        const combineData = {
            company_id : formData.cid,
            mapping_type :formData.mapping_type,
            import_type : importType,
            rowList : [updatedObj],
            updatedDMT : updatedDMT,
            existingDMTList:existingDMTList
        }
        console.log(combineData);
        setPopupOpen(true);
        try {
            const response = await axios.post(`${baseUrl}/digit-mapping/import`, combineData,{
                headers: {
                     Authorization: `Bearer ${token}`
                     
                }
            });
    
            const allArray = response.data;
            navigate("/digit-mapping");
        } catch (error) {
            console.error(error)
        }
        setPopupOpen(false)
        
    }
    return(
        <>
        {popupOpen && (
             <Loader /> 
        ) }
        {/* <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div style={{ display: "flex" }}>
                <div className="contact-input order-con-title">Associated DMT List</div>
            </div>     
        </div> */}
        <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
        <div className="row footer-row">
            <div className="footer-row-left">
                <Link className="Cancel" to={"/digit-mapping"}>Cancel</Link>                
            </div>
            <div className="footer-row-right">
                {/* <a href="#" className="Cancel">Cancel</a> */}
                
                {(digitMappingList.length > 0 || existingDigitMappingList.length > 0) && <a href="#" name="remove_all_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Remove all & Import</a>}

                {/* {selectedRows.length > 0 && <a href="#" name="remove_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Remove & Import</a>} */}
                {/* <a href="#" name="import" className={buttonDisabled ? 'next disabled-link' : 'next'} onClick={(e) => {handleNext(e)}} >Import</a> */}
            </div>
        </div>
        </div>
        {digitMappingList.length > 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                <div className="row">
                    <div className="order-con-title">Review Existing Associated DMT</div>  
                    
                </div>
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            {/* <th><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th> */}
                            <th>Alias</th>
                            <th>Origination ANI</th>
                            <th>Match Length</th>
                            <th>Origination DNIS</th>
                            <th>Match Length</th>
                            <th>Translated ANI</th>
                            <th>Translated DNIS</th>
                            <th>ANI Action</th>
                            <th>DNIS Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {digitMappingList.map((dmt, index) => (
                            <tr key={index}>
                                {/* <td>{selectedRows[0].alias}</td> */}
                            {/* <td><input type="checkbox"  checked={selectedRows.includes(dmt)} onChange={() => handleToggleSelect(index)} /></td> */}
                            <td>{dmt.alias}</td>
                            <td>{dmt.origAni}</td>
                            <td>{dmt.origAniMinLength} - {dmt.origAniMaxLength}</td>
                            <td>{dmt.origDnis}</td>
                            <td>{dmt.origDnisMinLength} - {dmt.origDnisMaxLength}</td>
                            <td>{dmt.transAni}</td>
                            <td>{dmt.transDnis}</td>
                            <td>{dmt.aniAction}</td>
                            <td>{dmt.dnisAction}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
            {digitMappingList.length === 0  && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div className="row">
                <div className="order-con-title">Review Existing Associated DMT</div>  
                
            </div>
            
            <div>No Record Found</div>
        </div>}
        {existingDigitMappingList.length > 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                <div className="row">
                    <div className="order-con-title">Below are the existing DMTs in the portal</div>  
                    
                </div>
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            {/* <th><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th> */}
                            <th>Alias</th>
                            <th>Origination ANI</th>
                            <th>Match Length</th>
                            <th>Origination DNIS</th>
                            <th>Match Length</th>
                            <th>Translated ANI</th>
                            <th>Translated DNIS</th>
                            <th>ANI Action</th>
                            <th>DNIS Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {existingDigitMappingList.map((dmt, index) => (
                            <tr key={index}>
                                {/* <td>{selectedRows[0].alias}</td> */}
                            {/* <td><input type="checkbox"  checked={selectedRows.includes(dmt)} onChange={() => handleToggleSelect(index)} /></td> */}
                            <td>{dmt.alias}</td>
                            <td>{dmt.original_ani}</td>
                            <td>{dmt.ani_min_length} - {dmt.ani_max_length}</td>
                            <td>{dmt.original_dnis}</td>
                            <td>{dmt.dnis_min_length} - {dmt.dnis_max_length}</td>
                            <td>{dmt.translated_ani}</td>
                            <td>{dmt.translated_dnis}</td>
                            <td>{dmt.ani_action == 0 ? "All" : (dmt.ani_action == 2 ? "Passthrough" : (dmt.ani_action == 3 ? "Prepend" : "Match"))}</td>
                            <td>{dmt.dnis_action == 0 ? "All" : (dmt.dnis_action == 2 ? "Passthrough" : (dmt.dnis_action == 3 ? "noLNP" : (dmt.dnis_action == 4 ? "Prepend" : "Match")))}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        
        {existingDigitMappingList.length === 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div className="row">
                <div className="order-con-title">Below are the existing DMTs in the portal</div>  
                
            </div>
            
            <div>No Record Found</div>
        </div>}

        
        </>
    );
}
 
const AddDigitMapping = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [dmtList, setDMTList] = useState([]);
    const [existingDMTList, setExistingDMTList] = useState([]);
    const [formData, setFormData] = useState({        
        cid: '',
        methodType: 0,
        mapping_type: "0",
        tn_number:'',
        alias:'',
        description:'',
        table_id: "0",
        translation_type: '',
       original_ani: '',
       ani_min_length:0,
       ani_max_length:40,
       original_dnis:'',
       dnis_min_length:0,
       dnis_max_length:40,
       translated_ani:'',
       translated_dnis:'',
       ani_action:'1',
       dnis_action:'1'

    });
    
    const [step, setStep] = useState(1);
   
    
    const navigate = useNavigate();

   
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }
    

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
 
    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} cid={cid}  role={role} token={token} formData={formData} setFormData={setFormData} setDMTList = {setDMTList} setExistingDMTList = {setExistingDMTList} />;
          case 2:
            return <Step2 onBack={handleBack} onNext={handleNext} token={token} formData={formData} setFormData={setFormData} dmtList = {dmtList} existingDMTList = {existingDMTList} />;
        //   case 5:
        //     return <Step5 data={formData} onFinish={handleFinish} />;
          default:
            return null;
        }
      };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page admin-new-addstaff-page">
                    {renderStep()}
                </div>
            </div>
        </div>
    )
}
 
export default AddDigitMapping