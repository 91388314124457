import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import Select from 'react-select';
import {Bar, Line} from 'react-chartjs-2'
import DatePicker from 'react-datepicker';
import formatDateTimeToPST from "../PSTDateConvert";
import { saveAs } from 'file-saver';
import Popup from './Popup';
import PopupImage from './PopupImage';
import 'chart.js/auto'; 


const CasDashboard = () => {
    const location = useLocation();
    const { compid } = location.state || {};
    const [name, setName] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(compid ?? '');
    const [casAccess, setCASAccess] = useState(false);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false)
    const [formData,setFormData] = useState({
        selectedCompany: compid ?? ''
    })
    const [ciaDetails,setCiaDetails] = useState({});
    const [ciaLastRunDetail, setCiaLastRunDetail] = useState("");
    const [ciaNextRunDetail, setCiaNextRunDetail] = useState("");
    const [graphDetails,setGraphDetails] = useState({})
    const [selectedCarrier,setSelectedCarrier] = useState(0);
    const today = new Date()
    const sixMonthsAgo = new Date(new Date().setMonth(today.getMonth() - 6));
    const [searchDate, setSearchDate] = useState(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'));
    const [searchEndDate, setSearchEndDate] = useState();
    const [displayDate, setDisplayDate] = useState(today);
    const [displayEndDate, setDisplayEndDate] = useState("");

    const [selectedNumber,setSelectedNumber] = useState('')
    const [numberGraphDetails,setNumberGraphDetails] = useState({})
    const [numberPeriodFilter,setNumberPeriodFilter] = useState('last_7_days')
    const [selectedCarrierNumber,setSelectedCarrierNumber] = useState(0)
    const [searchNumberDate, setSearchNumberDate] = useState(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'));
    const [searchNumberEndDate, setSearchNumberEndDate] = useState();
    const [displayNumberDate, setDisplayNumberDate] = useState(today);
    const [displayNumberEndDate, setDisplayNumberEndDate] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
        if(role > 2){
            setFormData(prev => ({...prev, selectedCompany:cid}))
        }
    }, [role]);

    useEffect(()=>{
        if(formData.selectedCompany && token){
            getCustomerCASAccess(formData.selectedCompany);
        }
    },[formData.selectedCompany, token]);

    useEffect(() => {
        if(casAccess == true && formData.selectedCompany){
            getCiaRunDetail(formData.selectedCompany);
            
        }
    }, [casAccess])
    

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
    }
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCiaStatistics = async (cid) => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/get-cia-statistics/${cid}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
           
            setCiaDetails(response.data);
            setPopupOpen(false);
        } catch (error) {
            console.log(error);  
            setPopupOpen(false);          
        }
    }
    const dayNameDisplay = (day) => {
        if(day === 1){
            return "Monday";
        }else if(day === 2){
            return "Tuesday";
        }else if(day === 3){
            return "Wednesday";
        }else if(day === 4){
            return "Thursday";
        }else if(day === 5){
            return "Friday";
        }else if(day === 6){
            return "Saturday";
        }else if(day === 7){
            return "Sunday";
        }
    }
    const getCiaRunDetail = async (cid) => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/get-cia-run-detail/${cid}`,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            const lastRunDetail = response.data.data?.[0]?.date ?? "";
            if(lastRunDetail != ""){
                setCiaLastRunDetail(lastRunDetail);
                setSearchDate(lastRunDetail.split(" ")[0]);
                setDisplayDate(new Date(lastRunDetail));
            }
           
            const schedule = response.data.ciaDetail?.[0];
            let description = '';
            if(schedule){
               
                if (schedule.cia_enable_switch === 1) {
                    
                    if (schedule.occurance_type === 1) {
                        description = `Runs ${(formatDateTimeToPST(schedule.format_date, "UTC", role)).split(" ")[0]} ${schedule.one_off_time}`;
                    }else if (schedule.occurance_type === 2) {
                        if (schedule.recurring_type === 1) {
                            description = `Runs everyday`;
                            if (schedule.recurring_time) {
                              description += ` at ${schedule.recurring_time}`;
                            }
                        }else if (schedule.recurring_type === 2) {
                            description = `Runs every ${dayNameDisplay(schedule.day_of_week)}`;
                            if (schedule.recurring_time) {
                              description += ` at ${schedule.recurring_time}`;
                            }
                        }else if (schedule.recurring_type === 3) {
                            // Monthly
                            description = `Runs every  ${schedule.day_of_month} ${(schedule.day_of_month == 1 || schedule.day_of_month == 21) ? 'st' : ((schedule.day_of_month == 2 || schedule.day_of_month == 22) ? "nd" : "th")} day of month`;
                            if (schedule.recurring_time) {
                              description += ` at ${schedule.recurring_time}`;
                            }
                        } else if (schedule.recurring_type === 4) {
                            // Every X days
                            description = `Runs every ${schedule.every_x_day} day`;
                            if (schedule.recurring_time) {
                              description += ` at ${schedule.recurring_time}`;
                            }
                        } 
                        console.log(description);
                    }
                }
               
            }
            setCiaNextRunDetail( description || 'No schedule defined');
            // setPopupOpen(false);
            getGraphStatistics(formData.selectedCompany, lastRunDetail);
        } catch (error) {
            console.log(error); 
            setPopupOpen(false);           
        }
    }

 

    const getGraphStatistics = async (cid, lastRunDetail) => {
        try{
            setPopupOpen(true);
            const start_date = lastRunDetail?.split(" ")[0] ?? searchDate;
            const response = await axios.post(`${baseUrl}/get-graph-cia-statistics/${cid}`,{carrier:selectedCarrier,fromDate:start_date,endDate:searchEndDate},{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setGraphDetails(response.data)
            setPopupOpen(false);
        }catch(error){
            console.log(error)
        }
    }

    const getGraphStatisticsForNumber = async (number) => {
        setPopupOpen(true)
        try{
            const response = await axios.post(`${baseUrl}/get-number-graph-cia-statistics/${number}`,{id: formData.selectedCompany, carrier: selectedCarrierNumber, fromDate:searchNumberDate,endDate:searchNumberEndDate},{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setPopupOpen(false)

            console.log(response.data)
            setNumberGraphDetails(response.data)
        }catch(error){
            console.log(error)
        }
    }
    

    const getCustomerCASAccess = async (cid) => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setPopupOpen(false);

            if (role > 2 && (response.data.cia_enable_switch === 0 || (response.data.cia_enable_switch === 1 && response.data.cia_account_type!== 1))) {
                navigate('/dashboard');
                return;
            }

            
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setCASAccess(true)
                    getCiaRunDetail(cid);
                    getCiaStatistics(cid);
                    getGraphStatistics(cid);
                    setShowNoMessageAccess(false)
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }
            

        } catch (error) {
           console.log(error);
           setPopupOpen(false);
        }
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);  
        setFormData({ ...formData, selectedCompany: e.target.value });
        setSelectedCarrier(0);
        setDisplayEndDate("");
        setSearchEndDate("");

    };

   

    const handleSelectedNumber = (data) => {
        setSelectedNumber(data.value)
    }

 
    const getCarrierName = (carrier) => {
        switch(carrier){
            case 1:
                return "T-Mobile";
            case 2:
                return "ATT"
            case 3:
                return "Verizon";
            default:
                return;
        }
    }

    const handleSearchDate = (date) => { 
        if(date){
            const formattedDate =
            date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
             date.getDate().toString().padStart(2, '0');
          
             
            setSearchDate(formattedDate);
            setDisplayDate(date);
        }
    };
    const handleSearchEndDate = (date) => { 
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchEndDate(formattedDate);
        setDisplayEndDate(date);
    }; 
    
    const handleCarrierWiseSearchSubmit = ()=> {
        getGraphStatistics(formData.selectedCompany)
    }

    const handleNumberSearchDate = (date) => { 
        console.log(date);  
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchNumberDate(formattedDate);
        setDisplayNumberDate(date);
    };
    const handleNumberSearchEndDate = (date) => { 
        console.log(date);  
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchNumberEndDate(formattedDate);
        setDisplayNumberEndDate(date);
    };

    const handleNumberWiseSearchSubmit = ()=> {
        if(selectedNumber){
            getGraphStatisticsForNumber(selectedNumber)
        }else{
            alert("Please Select a  Telephone Number.");
            return;
        } 
    }

    const gotoCiaEnableNumber = (e, type, carrier) => {
        e.preventDefault();
        navigate("/cia-number-detail", { state: {compid : formData.selectedCompany, type : type, carrier : carrier, filter: true} })
    }

    const gotoSwitchScreen = (e, type) => {
        e.preventDefault();
        
        const state = { state: {compid : formData.selectedCompany, filter: false}}
        if(type == 1){
            navigate("/cia-number-detail", state);
        }else if(type == 2){
            navigate("/number-call-history", state);
        }else if(type == 3){
            navigate("/cia-number-setting", state);
        }
        // navigate("/cia-number-detail", { state: {compid : formData.selectedCompany, type : type, carrier : carrier} })
    }

  return (
    <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page cia-dashboard">
                    <div className="row">
                    <div className="col-4">
                        <div className="left-title">
                            <h1>E-NRMS Dashboard</h1>
                        </div>
                    </div>
                    <div className="col-8 inventory-btn">
                    
                    {/* <a href="/cas-image-texttact" className='newrow' style={{marginLeft: '10px',paddingBottom: '15px'}}>TextRact Log</a> */}
                    
                        <div className="addnew-button">
                            {/* {role <= 2 && <a href="/cas-platform-setup" style={{marginLeft: '10px',paddingBottom: '15px'}}>Setup</a>} */}
                            <a href="#" style={{marginLeft: '10px',paddingBottom: '15px'}}  onClick={(e) => gotoSwitchScreen(e, 1)}>E-NRMS Number Details</a>
                            {(role <= 2) && <a href="#" style={{marginLeft: '10px',paddingBottom: '15px'}} onClick={(e) => gotoSwitchScreen(e, 2)}>Number Call History</a>}
                            {(role <= 2) && <a href="#" style={{marginLeft: '10px',paddingBottom: '15px'}} onClick={(e) => gotoSwitchScreen(e, 3)}>E-NRMS Enable</a>}
                            {/* <Link to="/cia-number-detail">E-NRMS Enabled Numbers</Link> */}

                            {/*  */}
                            {/* <Link to="/number-call-history">Number Call History</Link> */}
                        </div>
                    </div>
                    </div>

                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>}

                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}
                        
                    {casAccess && <>
                        <div className="common-white-shadow-background setting-section">
                        <div className='row'>
                            <div><strong>Last Process Run Date & Time (UTC):</strong> {ciaLastRunDetail && formatDateTimeToPST(ciaLastRunDetail, "UTC", role)}</div>
                        </div>
                        <div className='row'>
                            <div><strong>Schedule:</strong> {ciaNextRunDetail}</div>
                        </div> 
                        </div>
                        {(role <= 2) && <div className="common-white-shadow-background setting-section">
                            <div className='row'>
                                <div><strong>Total E-NRMS Enabled Numbers:</strong> {ciaDetails?.cia_enabled_numbers_count ?? ''}</div>
                            </div>
                            {ciaDetails?.cia_android_count && <><div className='row'>
                            <div className="setting-section haft-whitebox cia-table">
                                    <table className="tablebox search-result-table inventory-table">
                                        <thead>
                                            <tr>
                                                <th>Carrier</th>
                                                <th>Platform</th>
                                                <th>App - Last Active (UTC)</th>
                                                <th>App Version</th>
                                                <th>Broadcast Status</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ciaDetails?.deviceStatus.map((item, index)=>(
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.platform_type == 1 ? "Android" : "iOS"}</td>
                                                    <td>{formatDateTimeToPST(item.last_active, "UTC", role)}</td>
                                                    <td>{item.app_version ? item.app_version : "-"}</td>
                                                    <td>
                                                        <span className={item.broadcast_status == 1 ? 'success' : (item.broadcast_status == 2 ? 'error' : '')}>
                                                            {item.broadcast_status == 1 ? 'ON' : (item.broadcast_status == 2 ? "OFF" : "-")}
                                                        </span>
                                                    </td>
                                                    <td>
                                                    <span className={item.status === 'Online' ? 'success' : 'error'}>
                                                        {item.status}
                                                    </span>
                                                    </td>
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div> </>}
                        </div>}
                        <div className="common-white-shadow-background setting-section">
                        
                       

                        {ciaDetails?.cia_android_count && <>
                            <div className='row'>
                            <div><strong>Caller ID Status Summary (Android)</strong></div>
                        </div><div className="row">
                                <div className="setting-section haft-whitebox cia-table">
                                    <table className="tablebox search-result-table inventory-table">
                                        <thead>
                                            <tr>
                                                <th>Carrier</th>
                                                <th>Normal</th>
                                                <th>Spam</th>
                                                <th>Unknown</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ciaDetails?.cia_android_count.map((item, index)=>(
                                                <tr key={index}>
                                                    <td>{item.carrier_name}</td>
                                                    <td><a href="#" onClick={(e) => gotoCiaEnableNumber(e, 1, item.carrier)}>{item.normal_count}</a></td>
                                                    <td><a href="#" onClick={(e) => gotoCiaEnableNumber(e, 2, item.carrier)}>{item.spam_count}</a></td>
                                                    <td><a href="#" onClick={(e) => gotoCiaEnableNumber(e, 3, item.carrier)}>{item.unknown_count}</a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                        </div></> }
                        {ciaDetails?.cia_ios_count &&  <><div className='row' style={{marginTop : '20px'}}>
                            <div><strong>iOS</strong></div>
                        </div><div className="row">
                                <div className="setting-section haft-whitebox cia-table">
                                    <table className="tablebox search-result-table inventory-table">
                                        <thead>
                                            <tr>
                                                <th>Carrier</th>
                                                <th>Normal</th>
                                                <th>Spam</th>
                                                <th>Unknown</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ciaDetails?.cia_ios_count.map((item, index)=>(
                                                <tr key={index}>
                                                    <td>{item.carrier_name}</td>
                                                    <td><a href="#" onClick={(e) => gotoCiaEnableNumber(e, 1, item.carrier)}>{item.normal_count}</a></td>
                                                    <td><a href="#" onClick={(e) => gotoCiaEnableNumber(e, 2, item.carrier)}>{item.spam_count}</a></td>
                                                    <td><a href="#" onClick={(e) => gotoCiaEnableNumber(e, 3, item.carrier)}>{item.unknown_count}</a></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                        </div></>}
                        
                            
                        <div className="row admin-staff-list-toppart" style={{marginTop :'50px'}}>
                        <h3 className='comapny-label'>Carrier Analysis</h3>
                            <div className="col-md-8 ">
                                <div className='boxinput'>
                                    <select className="form-control" name='carriertype' value={selectedCarrier} onChange={(e)=>{setSelectedCarrier(e.target.value)}}>
                                        <option value='0'>All</option>
                                        <option value='1'>T-Mobile</option>
                                        <option value='2'>ATT</option>
                                        <option value='3'>Verizon</option>
                                    </select>
                                  
                                    <DatePicker
                                        selected={displayDate}
                                        onChange={handleSearchDate}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="From Date"
                                        className="from-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    />
                                    <DatePicker
                                        selected={displayEndDate}
                                        onChange={handleSearchEndDate}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="To Date"
                                        className="end-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    /> 

                                    <button className="search-btn" style={{marginLeft:'10px'}} onClick={handleCarrierWiseSearchSubmit}>Search</button>
                                </div>
                            </div>
                        </div>

                        { graphDetails?.android_carrier_1 && <div>
                            <StackedBarChart data={graphDetails?.android_carrier_1} carrier="T-Mobile" platform="Android" />
                        </div>
                        }
                        {graphDetails?.android_carrier_2 && <div>
                            <StackedBarChart data={graphDetails?.android_carrier_2} carrier="ATT" platform="Android" />
                        </div>
                        }
                        {graphDetails?.android_carrier_3 && <div>
                            <StackedBarChart data={graphDetails?.android_carrier_3} carrier="Verizon" platform="Android" />
                        </div>
                        }
                        { graphDetails?.ios_carrier_1 && <div>
                            <StackedBarChart data={graphDetails?.ios_carrier_1} carrier="T-Mobile" platform="iOS" />
                        </div>
                        }
                        {graphDetails?.ios_carrier_2 && <div>
                            <StackedBarChart data={graphDetails?.ios_carrier_2} carrier="ATT" platform="iOS" />
                        </div>
                        }
                        {graphDetails?.ios_carrier_3 && <div>
                            <StackedBarChart data={graphDetails?.ios_carrier_3} carrier="Verizon" platform="iOS" />
                        </div>
                        }
                        </div>
                        

                        <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Number-based Analysis</h3>
                        </div>
                        <div className="row admin-staff-list-toppart">
                            <div className="col-md-10">
                                
                                <div className='boxinput'> 
                                <Select
                                            classNamePrefix="select"
                                            name="number"
                                            className='search-select'
                                            placeholder="Select Number"
                                            onChange={(selectedOption)=>{
                                                handleSelectedNumber(selectedOption)
                                            }}
                                            options={ciaDetails?.cia_enabled_numbers_list?.map(item => ({
                                                value: item.tn_number,
                                                label: item.tn_number
                                            }))}
                                        />
                                    <select className="form-control" name='carriertype' value={selectedCarrierNumber} onChange={(e)=>{setSelectedCarrierNumber(e.target.value)}}>
                                        <option value='0'>All</option>
                                        <option value='1'>T-Mobile</option>
                                        <option value='2'>ATT</option>
                                        <option value='3'>Verizon</option>
                                    </select>

                                    <DatePicker
                                        selected={displayNumberDate}
                                        onChange={handleNumberSearchDate}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="From Date"
                                        className="from-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    />
                                    <DatePicker
                                        selected={displayNumberEndDate}
                                        onChange={handleNumberSearchEndDate}
                                        dateFormat="MM/dd/yyyy" // Set the desired date format
                                        placeholderText="To Date"
                                        className="end-date"
                                        minDate={sixMonthsAgo}
                                        maxDate={today}
                                    /> 

                                    <button className="search-btn" style={{marginLeft:'10px'}} onClick={handleNumberWiseSearchSubmit}>Search</button>
                                   
                                    {/* <select className="form-control" name="filtertypeNumber" value={numberPeriodFilter} onChange={handleNumberPeriodFilter}>
                                        <option value='last_7_days' >Last 7 Days</option>                                    
                                        <option value="last_30_days">Last 30 Days</option>
                                        <option value="last_3_month">Last 3 Month</option>
                                        <option value="last_6_month">Last 6 Month</option>
                                        <option value="last_1_year">Last 1 Year</option>
                                    </select> */}
                                </div>
                                    
                            </div>
                        </div>

                            {/* {numberGraphDetails?.cia_number_period_status_count && <div className='cia-graph-container'>
                                <LineChart period={numberPeriodFilter} data={numberGraphDetails?.cia_number_period_status_count}/>
                            </div>} */}

                            {numberGraphDetails?.cia_number_period_status_count && <div>
                                <NumberStatusLineChart data={numberGraphDetails?.cia_number_period_status_count} selectedValue={selectedValue} />
                            </div>}
                        </div>
                    </>}

                </div>
            </div>
    </div>
  )
}

export default CasDashboard



const NumberStatusLineChart = ({data, selectedValue}) => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const [isPopupTwoOpen, setIsPopupTwoOpen] = useState(false);
    const [selectedDataTwo, setSelectedDataTwo] = useState(null);

    const filteredData = data.filter(entry => entry.status !== null);
    const labels = filteredData.map(entry => new Date(entry.date).toLocaleDateString('en-US',{month:'short',day:'numeric'}))
    const statusData = filteredData.map(entry => entry.status + 1)
    const chartData = {
        labels: labels,
        datasets: [
            {
              label: 'Status',
              data: statusData,
              borderColor: '#144560',
              backgroundColor: '#144560',
              fill: false,
              pointRadius: 5, // Optional: Adjust point radius
            },
          ],
    }

    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const statusLabels = ['','Unverified', 'Verified', 'Spam'];
                return `Status: ${statusLabels[context.raw]}`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date',
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
            },
          },
          y: {
            title: {
              display: true,
              text: 'Status',
            },
            min: 0,
            max: 4,
            stepSize: 1,
            ticks: {
              callback: function (value) {
                const statusLabels = ['','Unverified', 'Verified', 'Spam'];
                return statusLabels[value];
              },
            },
          },
        },
      };
    
    const getStatus = (status) => {
        switch (status) {
            case 1:
                return 'Normal';
            case 2:
                return 'Spam';
            default:
                return 'Unknown'
        }
    }

    const getStatusBackgroundColor = (status) => {
        switch (status) {
            case 0:
                return '#FBBC05'; // Unverified color
            case 1:
                return '#34A853'; // Verified color
            case 2:
                return '#EA4335'; // Spam color
            default:
                return '#4285F4'; // Default color if status is not recognized
        }
      };

      const openPopup = async (e, item, cid) => {
        e.preventDefault();
        console.log();
        // const cid = formData.selectedCompany
        // console.log(item.tn_number,formData.selectedCompany,item.date);
    
        const response = await axios.post(`${baseUrl}/cia-get-ftc-data-by-tn-number`, { tnNumber: item.tn_number,cid: cid, date:item.date.split(' ')[0] });
    
        console.log(response?.data?.data);
    
        setPopupData(response.data.data);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const openPopupTwo = async (e, path) => {
        e.preventDefault();
        const response = await axios.post(`${baseUrl}/get-dialed-number-file`, {path});
        const { presignedUrl } = response.data;
        setSelectedDataTwo(presignedUrl);
        setIsPopupTwoOpen(true);
    };

    const closePopupTwo = () => {
        setIsPopupTwoOpen(false);
        setSelectedDataTwo(null);
    };

    return  (<div className="dialed_number_review_record">
    <div className=" setting-section haft-whitebox col-md-8">
        <table className="tablebox search-result-table inventory-table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Carrier</th>
                    <th>Stir Shaken Attestation Audit</th>
                    <th>CNAM Audit</th>
                    <th>Scan Risk Levels</th>
                    <th>Scan FTC Report</th>
                    <th>Caller ID Status (Android) </th>
                    <th>Caller ID Status (iOS) </th>
                </tr>
            </thead>
            <tbody>
                {filteredData.map((item, index)=>(
                    <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.carrier}</td>
                        <td>{item.attestationAudit}</td>
                        <td>{item.cnam}</td>
                        <td>{item.scanRiskLevel !== null && item.scanRiskLevel !== undefined ? `Risk level : ${item.scanRiskLevel}` : ""}</td>
                        <td>{item.scanFTCReport == 1 ? <a href="#" onClick={(e) => openPopup(e, item, selectedValue)}>{(item.subject !== null ? item.subject : 'Reported')}</a> : 'Nothing Reported'}</td>
                        <td style={{ backgroundColor: getStatusBackgroundColor(item.status) }}>{(item.status == 1 || item.status == 2)  ? <a  href="#" onClick={(e) => openPopupTwo(e, item.screenshot_android)}>{getStatus(item.status)}</a> : getStatus(item.status)}</td>
                        <td style={{ backgroundColor: getStatusBackgroundColor(item.status_ios) }}>{(item.status_ios == 1 || item.status_ios == 2)  ? <a  href="#" onClick={(e) => openPopupTwo(e, item.screenshot_ios)}>{getStatus(item.status_ios)}</a> : getStatus(item.status_ios)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    {isPopupOpen && <Popup data={popupData} onClose={closePopup} />}
    {isPopupTwoOpen && <PopupImage data={selectedDataTwo} onClose={closePopupTwo} />}
    </div>)

}


const StackedBarChart = ({data, carrier, platform})=> {
    const labels = data.map(entry => new Date(entry.date).toLocaleDateString('en-US',{month:'short',day:'numeric'}))
    const normalCounts = data.map(entry => entry.normal_count);
    // const unverifiedCounts = data.map(entry => entry.unverified_count);
    const spamCounts = data.map(entry => entry.spam_count);
    const unknownCounts = data.map(entry => entry.unknown_count);
    
    const chartData = {
        labels: labels,
        datasets:[
            {
                label: 'Normal',
                data: normalCounts,
                backgroundColor: '#34A853',
                borderColor: '#34A853',
                borderWidth: 1,
            },
            // {
            //     label: 'Unverified',
            //     data: unverifiedCounts,
            //     backgroundColor: '#FBBC05',
            //     borderColor: '#FBBC05',
            //     borderWidth: 1,
            // },
            {
                label: 'Spam',
                data: spamCounts,
                backgroundColor: '#EA4335',
                borderColor: '#EA4335',
                borderWidth: 1,
            },
            {
                label: 'Unknown',
                data: unknownCounts,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                borderWidth: 1,
            }
        ]
    }

    const isSingleDataset = data.length < 3;
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Caller ID Status - ${carrier} (${platform})`
            },
        },
        scales: {
            x: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Count',
                },
                beginAtZero: true,
            },
        },
        barThickness:  isSingleDataset ? 100 :"flex",
        maxBarThickness: isSingleDataset ? 100 :undefined ,
    };


    return <>
        <Bar data={chartData} options={options} />
    </>
}