
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
 
const OrderDetailLog = (props) => {

    const [detail, setDetail] = useState(props.detail);
    const [popupOpen, setPopupOpen] = useState(false);    
    useEffect(() => {
        setDetail(props.detail);
    }, [props.detail]); 
    
    const handleClick = async(e, type) => {
        e.preventDefault();
        
        try {
            setPopupOpen(true);
            let response;
            console.log(type);
            if(type == 0){
                response = await axios.get(`${baseUrl}/inteliquent-order-get/${detail.unique_id}`);
            }else if(type == 2){
                response = await axios.get(`${baseUrl}/sansay-order-get/${detail.unique_id}`);
            }else if(type == 3){
                response = await axios.get(`${baseUrl}/order-get/${detail.unique_id}`);
            }else if (type == 4){
                response = await axios.get(`${baseUrl}/order-detail-log/${detail.unique_id}?key=digit_mapping`);
            }else if(type == 5){
                response = await axios.get(`${baseUrl}/order-detail-log/${detail.unique_id}?key=digit_mapping/offnet`);
            }else{
                response = await axios.get(`${baseUrl}/bandwidth-order-get/${detail.unique_id}`);
            }
            
           
            setPopupOpen(false);
            // const newTab = window.open('_blank', 'noopener noreferrer');
            // newTab.document.write(`<div>API Response: ${JSON.stringify(response.data, null, 2)}</div>`);
            const newTab = window.open('', '_blank');
          newTab.document.write('<html><head><title>JSON Object</title></head><body><pre>' + JSON.stringify(response.data, null, 2) + '</pre></body></html>');
          newTab.document.close();
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
           setPopupOpen(false);
        }
     
          
      };
    return (
        <>
        {popupOpen && (
                     <Loader /> 
                ) }
        

        {detail && <>{detail.order_type == 0 && (detail.carrier_type == 0 || detail.carrier_type == 2) &&<div><a href='#' onClick={(e) => {handleClick(e,0)}}>Click Here to to view inteliquent response</a></div>}
        {detail.order_type == 0 && (detail.carrier_type == 1 || detail.carrier_type == 2) && <div><a href='#' onClick={(e) => {handleClick(e, 1)}}>Click Here to to view Bandwidth response</a></div>}
        {detail.order_type == 1 && <div><a href='#' onClick={(e) => {handleClick(e,2)}}>Sansay request log file</a></div>}
        {detail.order_type == 2 && (detail.carrier_type == 0 || detail.carrier_type == 2 || detail.carrier_type == 3) &&<div><a href='#' onClick={(e) => {handleClick(e,0)}}>Click Here to to view inteliquent response</a></div>}
        {detail.order_type == 2 && (detail.carrier_type == 1 || detail.carrier_type == 2 || detail.carrier_type == 3) && <div><a href='#' onClick={(e) => {handleClick(e, 1)}}>Click Here to to view Bandwidth response</a></div>}                       
        {detail.order_type == 3 && (detail.carrier_type == 0 || detail.carrier_type == 2) &&<div><a href='#' onClick={(e) => {handleClick(e,0)}}>Click Here to to view inteliquent response</a></div>}
        {detail.order_type == 3 && (detail.carrier_type == 1 || detail.carrier_type == 2) && <div><a href='#' onClick={(e) => {handleClick(e, 1)}}>Click Here to to view Bandwidth response</a></div>}
        {(detail.order_type != 1 && detail.order_type != 4 && detail.order_type != 5) && <div><a href='#' onClick={(e) => {handleClick(e, 3)}}>Click Here to to view logs</a></div>}  
        {(detail.order_type == 4 || detail.order_type == 5) && <div><a href='#' onClick={(e) => {handleClick(e, 4)}}>Click Here to to view logs</a></div>}
        {((detail.order_type == 4 || detail.order_type == 5) && detail.tn_details[0].mapping_type == 2) && <div><a href='#' onClick={(e) => {handleClick(e, 5)}}>Click Here to to view offnet logs</a></div>}</>}
        </>
    )
}
 
export default OrderDetailLog
