import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import Navbar from '../Navbar'
import axios from 'axios';
import baseUrl from '../BaseUrl';
import {schedulerUrl} from "../config";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import Loader from '../Loader';
import Pagination from 'react-js-pagination';
import formatDateTimeToPST from '../PSTDateConvert';
import DatePicker from 'react-datepicker';
import { saveAs } from 'file-saver';


const CiaBatchManagement = () => {
    const location = useLocation();
    const { compid, filter } = location.state || {};
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false); 

    const [casAccess, setCASAccess] = useState(false);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(compid);
    const [batchDates, setBatchDates] = useState([]);
    const [selectedBatchDate, setSelectedBatchDate] = useState("");
    const [batchList, setBatchList] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState("");    
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
            checkAccess();
    }, [role]);


    useEffect(()=>{
        if(selectedCompany && token){
            getCustomerCASAccess(selectedCompany);
            getCiaCallHistoryDate(selectedCompany);
        }
    },[selectedCompany, token]);

   
    useEffect(() => {
        if(selectedBatchDate && selectedCompany){
            getCiaCallHistoryBatchList(selectedCompany);
        }
    }, [selectedCompany, selectedBatchDate])

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
            seTimeZone(decoded.timezone);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const checkAccess = () => {
        if(role > 2){
            navigate('/dashboard');
        }
    }
   
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
        // setCompany(response.data);
    }

    
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCustomerCASAccess = async (cid) => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
           
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setCASAccess(true)
                    setShowNoMessageAccess(false)
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }

        } catch (error) {
           console.log(error);
        }
    };

    

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);  
    };

    const getCiaCallHistoryDate = async (cid) => {
        try {
            console.log(cid);
            const response = await axiosJWT.get(`${baseUrl}/get-cia-call-history-dates/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setBatchDates(response.data.result);
            setSelectedBatchDate( response.data.result[0].date);
        } catch (error) {
           console.log(error);
        }
    }

    const getCiaCallHistoryBatchList = async (cid) => {
        try {
            console.log(cid);
            const response = await axiosJWT.post(`${baseUrl}/get-cia-call-history-batch/${cid}`, {date: selectedBatchDate}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            
            setBatchList(response.data.result);
            setSelectedBatch( response.data.result[0].id);
        } catch (error) {
           console.log(error);
        }
    }

    const handleResumeSubmit = () => {
        try{
            setButtonDisabled(true);
            console.log(selectedBatch, selectedBatchDate, selectedCompany);
            axios.post(schedulerUrl, {
                batch: selectedBatch,
                batchDate: selectedBatchDate,
                cid: selectedCompany
            });
            alert("Process Resume for selected batch");
            window.location.reload();
        }catch (error) {
            setButtonDisabled(false);
           console.log(error);
            
        }
    };

   
  
  return (
    <div className="panelbox">
        <Navbar token={token} access={access}/>
        <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Number Call History</h1>
                            </div>
                        </div>
                        <div className="col-4 inventory-btn">
                        <div className="addnew-button">
                            {/* <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a> */}
                            <Link to="/cas-management"> Back</Link>
                        </div>
                    </div>
                    </div>

                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedCompany} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                    </div>}

                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}

                    {casAccess && <>
                        <div className="common-white-shadow-background setting-section">
                            <div className='row'>
                                <div><strong>Resume Process</strong></div>
                            </div>
                            <div className='row'>
                                <div className='col-10'>
                                    <div className='boxinput'>
                                        <select className="form-control" name='carrierStatus' value={selectedBatchDate} onChange={(e)=>{setSelectedBatchDate(e.target.value)}}>
                                          {batchDates && batchDates.map((item) => (
                                            <option value={item.date}>{item.date.split(" ")[0]}</option>
                                          ))}
                                        </select>
                                        <select className="form-control" name='carrierStatus' value={selectedBatch} onChange={(e)=>{setSelectedBatch(e.target.value)}}>
                                          {batchList && batchList.map((item) => (
                                            <option value={item.id}>{item.id}</option>
                                          ))}
                                        </select>
                                        <button className={buttonDisabled ? 'search-btn disabled-link' : 'search-btn'} style={{marginLeft:'10px'}} onClick={handleResumeSubmit}>Resume</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="common-white-shadow-background setting-section">
                            <div className="row cia-table cia-table-enable-number">
                                <div className=" setting-section haft-whitebox">
                                    <table className="tablebox search-result-table inventory-table cia-number-table">
                                        <thead>
                                            <tr>
                                                <th>Caller ID</th>
                                                <th>Carrier</th>
                                                <th>Device Number (Android)</th>
                                                <th>Device Status (Android)</th>
                                                <th>Boradcast Status (Android)</th>
                                                <th>Value of Release Cause (Android)</th>
                                                <th>Session Id (Android)</th>
                                                <th>Origination Trunk Id (Android)</th>
                                                <th>Termination Trunk Id (Android)</th>
                                                <th>Dialed Status (Android)</th>
                                                <th>Device Number (ios)</th>
                                                <th>Device Status (ios)</th>
                                                <th>Value of Release Cause (ios)</th>
                                                <th>Session Id (ios)</th>
                                                <th>Origination Trunk Id (ios)</th>
                                                <th>Termination Trunk Id (ios)</th>
                                                <th>Dialed Status (ios)</th>
                                                <th>Date-Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {callHistory.map((item, index)=>(
                                                <tr key={index}>
                                                    <td>{item.tn_number}</td>
                                                    <td>{item.carrier_name}</td>
                                                    <td>{item.device_number_android}</td>
                                                    <td>{item.device_status == null || item.device_status == 2 ? "Online" : "Offline"}</td>
                                                    <td>{item.broadcast_status_android == 2 ? "ON" : (item.broadcast_status_android == 1 ? "OFF" : "Unknown")}</td>
                                                    <td>{item.value_of_release_cause_android}</td>
                                                    <td>{item.session_id_android}</td>
                                                    <td>{item.origination_trunk_id_android}</td>
                                                    <td>{item.termination_trunk_id_android}</td>
                                                    <td>{item.dialed_status == null || item.dialed_status === 1 ? 'Failed' : 'Success'}</td>
                                                    <td>{item.device_number_ios}</td>
                                                    <td>{item.device_status_ios == null || item.device_status_ios == 2 ? "Online" : "Offline"}</td>
                                                    <td>{item.value_of_release_cause_ios}</td>
                                                    <td>{item.session_id_ios}</td>
                                                    <td>{item.origination_trunk_id_ios}</td>
                                                    <td>{item.termination_trunk_id_ios}</td>
                                                    <td>{item.device_status_ios == null || item.dialed_status_ios === 1 ? 'Failed' : 'Success'}</td>
                                                    <td>{formatDateTimeToPST(item.call_date_time, timeZone, role)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                   
                                </div>
                            </div>
                            {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                        </div> */}
                    </>}
                    
                </div>
        </div>
    </div>
  )
}

export default CiaBatchManagement